.zchat-msg-tp-dailychange-border {
  background: linear-gradient(90deg, rgb(67, 169, 223) 0%, rgb(0, 137, 99) 75.75%, rgb(0, 137, 99) 100%);
  padding: 1px 1px 0 1px;
}

.zchat-msg-tp-dailychange {
  overflow: hidden;
  background: rgb(240, 250, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  margin: 2px 0;
}

.zchat-msg-tp-dailychange-title {
  padding: 5px 0;
}

.zchat-msg-tp-dailychange-value {
  border-radius: 10px;
  padding: 5px;
  font-size: xx-large;
}

.zchat-msg-tp-dailychange-detail {
  font-size: small;
  padding: 5px 0;
}

.zchat-msg-tp-dailychange-row {
  display: grid;
  grid-template-columns: 55% auto;
  padding: 2px 0;
}

.zchat-msg-tp-dailychange-row-title {
  background: rgb(191, 226, 245);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.zchat-msg-tp-dailychange-row-value {
  padding: 5px;
  text-align: center;
}

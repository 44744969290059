.t2e-headline {
  background: rgb(0, 137, 99);
}

.HKPost.zt-th {
  font-size: x-small;
}

.HKPost.zt-td {
  font-size: x-small;
}

.HKPost.zt-tr {
  line-height: 1;
}

.HKPost.zchat-inputfield textarea::-webkit-scrollbar,
.HKPost.zchat-msg-qrlist::-webkit-scrollbar,
.HKPost.zchat-msg-tp-text::-webkit-scrollbar,
.HKPost.zchat-msglist::-webkit-scrollbar,
.HKPost.zchat-msg-tlist::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.HKPost.zchat-inputfield textarea::-webkit-scrollbar-track,
.HKPost.zchat-msg-qrlist::-webkit-scrollbar-track,
.HKPost.zchat-msg-tp-text::-webkit-scrollbar-track,
.HKPost.zchat-msglist::-webkit-scrollbar-track,
.HKPost.zchat-msg-tlist::-webkit-scrollbar-track {
  background: rgb(233, 242, 247);
}

/* Handle */
.HKPost.zchat-inputfield textarea::-webkit-scrollbar-thumb,
.HKPost.zchat-msg-qrlist::-webkit-scrollbar-thumb,
.HKPost.zchat-msg-tp-text::-webkit-scrollbar-thumb,
.HKPost.zchat-msglist::-webkit-scrollbar-thumb,
.HKPost.zchat-msg-tlist::-webkit-scrollbar-thumb {
  background: rgb(0, 137, 99);
}

/* Handle on hover */
.HKPost.zchat-inputfield textarea::-webkit-scrollbar-thumb:hover,
.HKPost.zchat-msg-qrlist::-webkit-scrollbar-thumb:hover,
.HKPost.zchat-msg-tp-text::-webkit-scrollbar-thumb:hover,
.HKPost.zchat-msglist::-webkit-scrollbar-thumb:hover,
.HKPost.zchat-msg-tlist::-webkit-scrollbar-thumb:hover {
  background: rgb(187, 215, 241);
}

.HKPost.zchat-send {
  margin: 5px 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.HKPost.zchat-send:hover {
  background-color: transparent;
}

.HKPost.zchat-bubble.in {
  border-radius: 30px 10px 35px 0;
}

.HKPost.zchat-bubble.out {
  background: rgb(0, 137, 99);
  border-radius: 10px 30px 0 35px;
}

.HKPost.zt-th {
  background: rgb(51, 113, 172);
}

.HKPost.zt-tr:nth-child(2n) {
  background-color: rgb(230, 241, 255);
}

.HKPost.zt-tr:last-child {
  border-bottom-width: 0px;
}

.HKPost.ztablizar {
  border: 0px solid rgba(200, 123, 30, 0.315);
}

.HKPost.zt-main.zt-scrollable {
  height: auto;
  padding: 1px;
}

.HKPost.zchat-quickreplybar-btn {
  background: rgb(0, 137, 99);
  font-size: calc(12px + 0.2vw);
}

.HKPost.zchat-quickreplybar-btn-text:hover {
  background: rgb(0, 137, 99);
}

@media only screen and (max-width: 768px) {
  .HKPost.zchat-quickreplybar-btn-text.safari {
    font-size: 16px;
  }

  .HKPost.zchat {
    font-size: calc(16px + 0.1vw);
  }

  .HKPost.ztablizar {
    max-width: 99%;
  }

  .HKPost.zchat-msg-text {
    overflow-wrap: break-word;
    font-size: calc(16px + 0.1vw);
  }

  .HKPost.zchat-msg-footer {
    font-size: xx-small;
  }

  .HKPost.zchat-send {
    color: rgb(0, 137, 99);
    margin: 5px 5px;
    padding: 0;
    width: 25px;
    height: 25px;
    background-color: transparent;
  }

  .HKPost.zchat-input.safari {
    height: 30px;
    height: calc(30px + var(--vh, 1vh) * 1);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Calibri, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(233, 242, 247);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(67, 169, 223);
  background: linear-gradient(180deg, rgb(67, 169, 223) 0%, rgb(0, 137, 99) 75.75%, rgb(0, 137, 99) 100%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(44, 90, 136);
}

input {
  padding-left: 5px;
  padding-right: 5px;
}

.HIDDEN {
  display: none;
}

/* Pages Standard*/
.zbmain {
  width: 95%;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 25px 25px;
  border-color: rgba(200, 123, 30, 0.2);
  padding: 15px;
  margin: 0 5px 5px 5px;
}

.zb5050 {
  display: flex;
  justify-content: space-evenly;
}

.zbbox {
  width: 95%;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(200, 123, 30, 0.2);
  padding: 15px;
  margin: 0 5px 5px 5px;
}

.zbpopup {
  position: absolute;
  min-width: 400px;
  max-width: 70%;
  height: auto;
  text-align: center;
  border-radius: 15px;
  background: #f9fffd;
  top: 45%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.zbfilter {
  width: 100%;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px 5px 0 0;
  border-color: rgba(200, 123, 30, 0.2);
  display: flex;
  justify-content: flex-end;
}

.zbtitle {
  font-size: 1.4rem;
  font-weight: bold;
  color: rgb(200, 123, 30);
}

.zbpages {
  display: inline-flex;
  margin-left: 20px;
}

.zbpages-btn {
  font-size: 1rem;
  font-weight: bold;
  color: rgb(200, 123, 30);
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px 5px 0 0;
  border-color: rgba(200, 123, 30, 0.2);
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
}

.zbpages-btn i {
  padding-right: 3px;
}

.zbpages-btn:hover {
  color: rgb(255, 123, 30);
}

.zbpages-btn.current {
  background: rgb(200, 123, 30);
  color: rgb(235, 235, 235);
}

.Convlog.zbcontent {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(200, 123, 30, 0.2);
}

.zbcontent.hidden {
  display: none;
}

.zbjsonviewer {
  text-align: left;
  padding: 5px;
  height: 80vh;
  overflow: auto;
}

/* Page */
.zbpages {
  display: inline-flex;
  margin-left: 20px;
}

.zbpages-btn {
  font-size: 1.1rem;
  font-weight: bold;
  color: rgb(200, 123, 30);
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px 5px 0 0;
  border-color: rgba(200, 123, 30, 0.2);
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
}

.zbpages-btn:hover {
  color: rgb(255, 123, 30);
}

.zbpages-btn.current {
  background: rgb(200, 123, 30);
  color: rgb(235, 235, 235);
}

.zbcontent.hidden {
  display: none;
}

.zbjsonviewer {
  text-align: left;
  padding: 5px;
  max-height: 85vh;
  overflow: auto;
}

.zchat-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-top: 10px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .zchat-input.safari {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 60px;
  }
}

.zchat-inputbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  min-width: 50px;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  border-color: rgba(200, 200, 200, 1);
  margin-right: 15px;
}

.zchat-inputfield {
  width: 100%;
  margin: 5px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.zchat-inputfield textarea {
  font-size: 16px;
  width: 100%;
  resize: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
}

.zchat-inputfield textarea:focus {
  outline: none;
}

.zchat-send {
  margin: 5px 10px;
  padding: 5px;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: rgb(212, 120, 0);
  background: rgb(255, 196, 141);
}

.zchat-send:hover {
  color: rgb(255, 123, 30);
  background: rgba(200, 123, 30, 0.2);
}

.zchat-send img {
  width: 100%;
  height: 100%;
}

.zchat-inputfield.en textarea {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 768px) {
  .zchat-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 30px;
    padding-top: 5px;
  }

  .zchat-inputbox {
    margin-right: 5px;
  }

  .zchat-inputfield {
    margin: 5px 5px;
  }

  .zchat-inputfield textarea {
    font-size: 16px;
  }

  .HKPost.zchat-send {
    margin: 5px 5px;
    padding: 0;
    width: 25px;
    height: 25px;
    background-color: transparent;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .zchat-inputfield textarea {
    font-size: 16px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow: auto;
  }

  .zchat-inputbox {
    height: 35px;
  }
}

.ztablizar{
  display: flexbox;
  text-align: center; 
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(200, 123, 30, 0.315);
  position: relative;
}

.zt-main{
  height: 100%;
}

.zt-main.zt-scrollable{
  height: 60vh;
  overflow-y: scroll;
}

.zt-table{
  align-items: center;
  table-layout: auto;
  border-collapse: collapse;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.zt-main.zt-scrollable thead th{
  position: sticky;
  top: 0px;
  line-height: 2;
  border: 1px;
}

.zt-tr{
  line-height: 1.5;
}

.zt-tr:hover{
  background-color: rgb(255, 252, 211);
}

.zt-tr:nth-child(2n){
  background-color: rgb(252, 242, 222);
}

.zt-tr:nth-child(2n):hover{
  background-color: rgb(255, 252, 211);
}

.zt-tr:last-child{
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: rgba(200, 123, 30, 0.2);
}

.zt-th{
  line-height: 1.4rem;
  padding-left: 5px;
  padding-right: 5px;
  border: solid;
  border-width: 1px;
  border-color: rgba(207, 207, 207, 0.3);
  background: rgb(200, 123, 30);
  color: rgb(235, 235, 235);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.zt-th.desc{
  background: linear-gradient(0deg, rgb(200, 123, 30), rgb(128, 128, 128));
}

.zt-th.asc{
  background: linear-gradient(0deg, rgb(128, 128, 128), rgb(200, 123, 30));
}

.zt-th:hover{
  background: rgb(255, 123, 30);
}

.zt-td{
  line-height: 1.7;
  padding-left: 5px;
  padding-right: 5px;
  border: solid;
  border-width: 1px;
  border-color: rgba(207, 207, 207, 0.3);
}

.zt-td.hidden{
  -webkit-text-security: disc;
}

.zt-td:hover{
  background-color: rgb(255, 253, 154);
}

.zt-col:hover{
  background-color:red;
}

.zt-footer{  
  line-height: 2rem;
  padding-left: 5px;
  padding-right: 5px;
  display: flexbox;
  z-index: 1;
}

.zt-page-flip{
  display: inline-flex;
}

.zt-page-btn{
  cursor: pointer;
  color: rgb(200, 123, 30);
  margin-left: 2px;
  margin-right: 2px;
}

.zt-footer-btn{
  cursor: pointer;
  color: rgb(200, 123, 30);
  margin-left: 2px;
  margin-right: 2px;
  user-select: none;
  width: 30px;
}

.zt-footer-btn.left{
  float: left;
}

.zt-footer-btn.right{
  float: right;
}

.zt-cellBtn{
  cursor: pointer;
  color: rgb(200, 123, 30);
  margin-left: auto;
  margin-right: auto;
  width: 20px;
}

.zt-cellBtn:hover,
.zt-page-btn:hover,
.zt-footer-btn:hover{
  color: rgb(255, 123, 30);
}

.zt-page-input{
  border-style: solid;
  border-width: 1px;
  border-color: rgb(200, 123, 30);
  border-radius: 5px;
  margin: 2px;
  width: 30px;
  text-align: center;
}

.zt-page-input[type="number"]::-webkit-outer-spin-button,
.zt-page-input[type="number"]::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

.zt-page-input[type="number"] {
  -moz-appearance: textfield;
}

.zt-page-total{
  margin-left: 2px;
  margin-right: 2px;
}

.zt-no-data{
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 0;
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.zt-navbar{
  display: flex;
  width: 100%;
}

.zt-pagesizeoption{
  float: left;
  display: flex;
  padding: 2px 5px;
  justify-content: left;
  align-items: center;
}

.zt-pageindicator{
  margin-left: auto;
  float: right;
  display: flex;
  padding: 2px 5px;
  justify-content: right;
  align-items: center;
}

.zt-pso-text{
  font-weight: bold;
  color: rgb(200, 123, 30);
  margin: 0 5px;
}

.zt-pso-text-bold{
  font-weight: bold;
  color: rgb(255, 123, 30);
  margin: 0 5px;
}

.zt-dd-container{
  width: 100px;
}

.zt-ZDropDownList{
  width: 100%;
}

.zt-dd-header{
  cursor: pointer;
  top: -1px;
  position: relative;
  width: 100%;
  height: 30px;
  display: inline-flex;
  transition: background-color 0.5s;
  border-radius: 5px;
  padding-bottom: 1px;
  padding-top: 1px;
}

.zt-dd-header:hover{
  background-color: rgb(255, 238, 218);
}

.zt-dd-header:hover .zt-dd-header-title{
  color: rgb(255, 123, 30);
}

.zt-dd-header:hover .zt-dd-header-arrow{
  color: rgb(255, 123, 30);
}

.zt-dd-header-title{
  font-weight: bold;
  width: 75%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: rgb(255, 123, 30);
}

.zt-dd-header-arrow{
  cursor: pointer;
  width:25%;
  line-height: 30px;
  text-align: center;
  color:  rgb(200, 200, 200);
}

.zt-dd-list{
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: white;
  border-radius: 0 0 5px 5px;
  width: 100px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  margin-top: -2px;
  z-index: 3;
}

.zt-dd-list-item{
  cursor: pointer;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color:  rgb(31, 31, 31);
  background-color: #f1f1f1;
  transition: background-color 0.5s, visibility 0.5s, opacity 0.5s linear;
  padding-top: 3px;
  padding-bottom: 3px;
}

.zt-dd-list-item:last-child{
  border-radius: 0 0 5px 5px;
}

.zt-dd-list-item:hover{
  color: rgb(255, 123, 30);
  background-color: rgb(255, 238, 218);
}
.NoMatch img{
  margin-top: -5px;
  margin-left: -50px;
  width: 110%;
  position: fixed;
}

.NoMatch .nopage{
  position: fixed;
  top: 10%;
  left: 45%;
  color: rgb(235, 235, 235);
  font-weight: 800;
  font-size: 12vw;
  z-index: 2;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .t2e-headline {
    height: auto;
    display: block;
  }

  .t2e-headline-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 10px 5px;
  }

  .t2e-headline-text {
    align-items: center;
    display: inline;
    position: relative;
    padding: 0px;
    top: -8px;
  }

  .t2e-headline-left {
    display: inline-block;
    height: 50px;
  }

  .t2e-headline-right {
    display: inline-block;
    height: 45px;
  }

  .t2e-reminder {
    height: auto;
  }

  .t2e-chat {
    padding-top: 20px;
  }

  .t2e-reminder.hide {
    display: none;
  }
}

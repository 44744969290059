.zreduxform{
  margin: 10px;
  text-align: center;
}

.zrf-title{
  font-size: 1.2rem;
  font-weight: bold;
  color: rgb(200, 123, 30);
  padding: 1px;
}

.zrf-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zrf-box{
  display: flex;
  flex-direction: column;
  width: 95%;
  height: auto;
  max-height: 75vh;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(200, 123, 30, 0.2);
  margin: 1px auto;
  padding: 5px;
  overflow: auto;
}

.zrf-box.no-box{
  border: none;
}

.zrf-field{
  display: grid;
  width: 100%;
  text-align: center;
  margin: 2px;
  line-height: 20px;
  height: auto;
  grid-template-columns: 30% auto;
}

.zrf-field-inline{
  display: flex;
  flex-direction: row;
  width: 95%;
}

.zrf-field-inline-field{
  display: flex;
  width: 100%;
}

.zrf-field-inline-field input{
  padding: 2px 5px;
  width: 90%;
}

.zrf-field input{
  padding: 2px 5px;
  width: 90%;
}

.zrf-field textarea{
  padding: 2px 5px;
  width: 90%;
}

.zrf-field td:not(:first-child){
  text-align: left;
}

.zrf-field-title{
  text-align: right;
  color: rgb(200, 123, 30);
  line-height: 20px;
  margin-right: 5px;
  font-weight: bold;
}

.zrf-field-inner{
  text-align: right;
  color: rgb(200, 123, 30);
  line-height: 20px;
  margin-left: 5px;
  font-weight: bold;
}

.zrf-field-file,
.zrf-field-date,
.zrf-field-textfield,
.zrf-field-number{
  border-style: solid;
  border-width: 1px;
  border-color: rgba(200, 123, 30, 0.2);
  margin-left: 5px;
  border-radius: 5px;
  line-height: 20px;
}

.zrf-field-data{
  color: rgba(200, 123, 30);
  margin: 0 5px;
}

.zrf-field-textfield.notresizeable{
  resize: none;
}

.zrf-field-file.error,
.zrf-field-date.error,
.zrf-field-textfield.error,
.zrf-field-number.error{
  border-color: rgba(255, 0, 0, 0.397);
  box-shadow: 0 0 2px rgba(255, 0, 0, 0.397) ;
}

.zrf-field-dropdown{
  border-style: solid;
  border-width: 1px;
  border-color: rgba(200, 123, 30, 0.2);
  margin-left: 5px;
  border-radius: 5px;
  line-height: 20px;
  width: 70%;
  padding-top: 3px;
  padding-bottom: 3px;
  
}

.zrf-field-textfield.long{
  width:95%;
}
.zrf-field-textfield.short{
  width:80%;
  margin-left: 15px;
}

.zrf-btnrow{
  display: inline-flex;
}

.zrf-btn{
  color: rgb(200, 123, 30);
  background: rgba(255, 255, 255, 0.459);
  border-color: rgba(200, 123, 30, 0.2);
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 2px rgba(200, 123, 30, 0.7);
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  line-height: 20px;
  cursor: pointer;
  user-select: none;
}

.zrf-btn.no-box{
  border-width: 1px;
  border-radius: 5px;
}

.zrf-btn:focus{
  outline: 0;
}

.zrf-btn:hover{
  color: rgb(255, 123, 30);
  background: rgb(255, 232, 197);
  border-color: rgba(200, 123, 30, 0.2);
}

.zrf-btn.ok:hover{
  color: rgb(80, 80, 80);
  background: rgb(197, 197, 197);
  border-color: rgba(80, 80, 80, 0.2);
}

.zrf-btn.submit:hover{
  color: rgb(85, 136, 38);
  background: rgb(198, 239, 206);
  border-color: rgba(85, 136, 38, 0.2);
}

.zrf-btn.cancel:hover{
  color: rgb(163, 1, 8);
  background: rgb(255, 199, 206);
  border-color: rgba(163, 1, 8, 0.2)
}

.zrf-btn.revert:hover{
  color: rgb(157, 88, 22);
  background: rgb(255, 235, 156);
  border-color: rgba(157, 88, 22, 0.2)
}

.zrf-btn.clear:hover{
  color: rgb(74, 98, 136);
  background: rgb(155, 194, 230);
  border-color: rgba(74, 98, 136, 0.2)
}

.zrf-btn:disabled{
  color: rgba(0, 0, 0, 0.212);
  background: rgba(255, 255, 255, 0.459);
}

.zrf-btn:hover:disabled{
  color: rgba(0, 0, 0, 0.212);
  background: rgba(255, 255, 255, 0.459);
}

.zrf-header{
  font-weight: bold;
  color: rgb(157, 88, 22);
}

.zrf-field-radio{
  display: block;
  color: rgb(200, 123, 30);
}

.zrf-field-radio.error{
  color: rgb(163, 1, 8);
}

.zrf-dropzone{
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-style: dashed;
  border-color: rgb(80, 80, 80);
  border-width: 5px;
  background: yellow;
}

.zrf-field-file::-webkit-file-upload-button{
  color: rgb(200, 123, 30);
  background: rgba(255, 255, 255, 0.459);
  border-color: rgba(200, 123, 30, 0.2);
  border-style: solid;
  border-width: 0 1px 0 0;
  border-radius: 5px;
  margin-left: -5px;
  width: 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  line-height: 20px;
}

.zrf-field-file::-webkit-file-upload-button:hover{
  color: rgb(157, 88, 22);
  background: rgb(255, 235, 156);
  border-color: rgba(157, 88, 22, 0.2);
}

.zrf-array-box{
  width: 98%;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(200, 123, 30, 0.2);
  padding: 5px;
}

.zrf-array-table{
  width: 100%;
}

.zrf-array-label{
  width: 100%;
  font-size: 1.1em;
  font-weight: bold;
  color: rgb(200, 123, 30);
}

.zrf-array-header-row{
  font-weight: bold;
  color: rgb(157, 88, 22);
}

.zrf-array-table input{
  width: 100%;
}

.zrf-cellBtn{
  cursor: pointer;
  color: rgb(200, 123, 30);
  margin-left: auto;
  margin-right: auto;
  width: 20px;
}

.zrf-cellBtn:hover{
  color: rgb(255, 123, 30);
}

.zrf-array-table td{
  vertical-align: top;
}

.zrf-array-table input{
  margin: 0;
  padding: 0 5px;
  width: 95%;
}

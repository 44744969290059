.ZPopup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
}

.ZPopup-bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.zpopup-form {
  text-align: center;
  position: absolute;
  min-width: 400px;
  max-width: 600px;
  width: 40%;
  height: auto;
  text-align: center;
  border-radius: 15px;
  background: #f9fffd;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.zpopup-loading-form {
  text-align: center;
  position: absolute;
  min-width: 400px;
  max-width: 600px;
  width: 40%;
  height: auto;
  text-align: center;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.zpopup-box {
  width: 95%;
  min-height: 80px;
  height: auto;
  margin: 1px 1px 0 1px;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zpopup-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(0, 137, 99);
  padding: 1px;
}

.zpopup-btnrow {
  display: inline-flex;
  margin-bottom: 15px;
}

.zpopup-btn {
  color: rgb(0, 137, 99);
  background: rgba(255, 255, 255, 0.459);
  border-color: rgba(0, 137, 99, 0.2);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 137, 99, 0.7);
  margin-left: 5px;
  margin-right: 5px;
  width: 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  line-height: 20px;
  cursor: pointer;
}

.zpopup-btn:hover {
  color: rgb(255, 123, 30);
  background: rgb(255, 232, 197);
  border-color: rgba(200, 123, 30, 0.2);
}

.zpopup-btn.submit:hover {
  color: rgb(85, 136, 38);
  background: rgb(198, 239, 206);
  border-color: rgba(85, 136, 38, 0.2);
}

.zpopup-btn.cancel:hover {
  color: rgb(163, 1, 8);
  background: rgb(255, 199, 206);
  border-color: rgba(163, 1, 8, 0.2);
}

.zpopup-btn.revert:hover {
  color: rgb(157, 88, 22);
  background: rgb(255, 235, 156);
  border-color: rgba(157, 88, 22, 0.2);
}

.zpopup-btn.clear:hover {
  color: rgb(74, 98, 136);
  background: rgb(155, 194, 230);
  border-color: rgba(74, 98, 136, 0.2);
}

.zpopup-btn:disabled {
  color: rgba(0, 0, 0, 0.212);
  background: rgba(255, 255, 255, 0.459);
}

.zpopup-btn:hover:disabled {
  color: rgba(0, 0, 0, 0.212);
  background: rgba(255, 255, 255, 0.459);
}
